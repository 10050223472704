import restApi from '@/utils/rest-api'

export default {
    searchArticle: (page, pageSize, query) => {
        const filter = { inSyllabus: true }
        if (query) {
            filter.q = query
        }
        const range = [page, pageSize]
        const filterStr = encodeURIComponent(JSON.stringify(filter))
        const rangeStr = encodeURIComponent(JSON.stringify(range))

        return restApi.get(`articles?filter=${filterStr}&range=${rangeStr}`)
    },
    getArticle: (articleId) => {
        return restApi.get(`articles/${articleId}`)
    }
}
