<template>
    <b-container fluid>
        <b-row class="mr-0 mt-3 vertical-align">
            <b-col>
                <span :id="`article-${article.id}`" class="pl-3 title float-left">{{ $t('library.title') }}</span>
            </b-col>
            <b-col />
            <b-col>
                <div class="back-to-main" @click="goToLibrary()">
                    <font-awesome-icon :icon="['fas','arrow-circle-left']" />
                    <span class="ml-2">{{ $t('library.backΤoLibrary') }}</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-container
                class="my-2"
                fluid
            >
                <b-card no-body>
                    <b-row
                        no-gutters
                        class="py-2 px-4 to-library"
                    >
                        <span
                            class="pointer-text"
                            @click="goToLibrary"
                        >
                            {{ $t('library.title') }}
                        </span>
                        <span class="px-2">></span>
                        <span>{{ article.title }}</span>
                    </b-row>
                    <b-row no-gutters class="py-2 px-4 h-100">
                        <b-col lg="2">
                            <div class="text-first pb-2">{{ $t('library.educationalGuide') }}</div>
                            <div class="text-second pb-4">
                                {{ article.title }}
                            </div>
                        </b-col>
                        <b-col>
                            <b-card-body class="article-content">
                                <div v-dompurify-html="article.data" />
                            </b-card-body>
                        </b-col>
                    </b-row>
                </b-card>
            </b-container>
        </b-row>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    import articlesService from '@/api/articles'

    export default {
        name: 'Mission',
        mixins: [ config ],
        data() {
            return {
                article: {}
            }
        },
        created() {
            articlesService.getArticle(this.$route.params.articleId).then((res) => {
                this.article = res
            })
            setTimeout(() => {
                document.querySelector('#article-' + this.article.id).scrollIntoView()
            }, 100)
        },
        methods: {
            goToLibrary() {
                this.$router.push(
                    {
                        path: `/library`
                    }
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
.back-to-main {
    text-align: center;
    color: var(--main-content-text);
    cursor: pointer;
}

.to-library {
    font-weight: 700;
    font-size: 14px;
}

.pointer-text {
    cursor: pointer;
}

.text-first {
    text-align: left;
    font-size: 14px;
    color: var(--info-color);
}

.text-second {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    color: var(--black);
}

.title {
    font-weight: 700;
    font-size: 28px;
    color: var(--main-content-text);
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.article-content {
    color: var(--black);
    font-size: 1em;
}

.article-content div {
    width: 100%;
    margin: 0 auto;
    text-align: initial !important;
}

</style>
<style>
iframe {
    width: 100% !important;
    aspect-ratio: 16/9;
}

span.ql-size-small {
    font-size: 0.75em !important;
}

span.ql-size-large {
    font-size: 1.5em !important;
}

span.ql-size-huge {
    font-size: 2.5em !important;
}
</style>
